import { SafetyAlertDto } from '../dto/safety-alert-dto';
import { Keyword } from './keyword';

export class SafetyAlert implements SafetyAlertDto {
  id: number;
  tenantId: number;
  archived: boolean;
  createdOn: Date;
  lastUpdatedOn: Date;
  createdBy: string;
  updatedBy: string;
  title: string;
  isActive: boolean;
  isRegulated: boolean;
  uniqueId: string;
  summary: string;
  info: string;
  pdfFilepath: string;
  keywords: Keyword[];

  constructor(alert?: SafetyAlert | SafetyAlertDto) {
    this.id = alert ? alert.id : null;
    this.archived = alert ? alert.archived : false;
    this.createdOn = alert ? alert.createdOn : new Date();
    this.lastUpdatedOn = alert ? alert.lastUpdatedOn : new Date();
    this.createdBy = alert ? alert.createdBy : null;
    this.updatedBy = alert ? alert.updatedBy : null;
    this.tenantId = alert ? alert.tenantId : null;
    this.title = alert ? alert.title : null;
    this.isActive = alert ? alert.isActive : null;
    this.isRegulated = alert ? alert.isRegulated : false;
    this.uniqueId = alert ? alert.uniqueId : null;
    this.summary = alert ? alert.summary : null;
    this.info = alert ? alert.info : null;
    this.pdfFilepath = alert ? alert.pdfFilepath : null;
    this.keywords = alert ? alert.keywords : [];
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  createUserObjFromUserRowRecord,
  UserRow,
} from '../../../../../core/dto/userRowRecord';
import {Group, SaUserAssignment, UserObj} from '../../../../../core/models';
import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { SortOrder } from '../../../../../core/enums/sortOrder';

@Injectable({
  providedIn: 'root',
})
export class MyTeamService {
  private _myTeam = new BehaviorSubject<UserObj[]>([]);

  public readonly myTeam = this._myTeam.asObservable();

  constructor(private http: HttpClient, private userService: UserService) {}

  public triggerMyTeamRequest(): void {
    this.fetchMyTeam().subscribe((myTeam) => {
      this._myTeam.next(myTeam);
    });
  }

  public removeTeamMember(id: number): Promise<UserRow> {
    const url = `${environment.apiV2}/users/my-team/remove`;
    const body = {
      teamMemberId: id,
    };
    return this.http.put<UserRow>(url, body).toPromise();
  }

  public async clearTeamMembers(): Promise<void> {
    const url = `${environment.apiV2}/users/my-team/clear`;

    try {
      await this.http.delete<void>(url).toPromise();
      this._myTeam.next([]);
    } catch (error) {
      console.error('Error clearing team members:', error);
      throw error;
    }
  }

  public addTeamMembers(ids: number[]): Promise<UserRow> {
    const url = `${environment.apiV2}/users/my-team`;
    const body = {
      teamMemberIds: ids,
    };
    return this.http.post<UserRow>(url, body).toPromise();
  }

  public addAllTeamMembersWithFilters(search: string, locations: string[], groups: string[], costCenters: string[]): Promise<UserRow> {
    const url = `${environment.apiV2}/users/my-team/filtered`;
    const body = {
      locations,
      groups,
      costCenters
    };
    return this.http.post<UserRow>(url, body).toPromise();
  }


  // Search users in logged in user's group and child groups,
  // sorted in alpha order ASC by last name.
  // Excludes logged in user and existing members of their team.
  // Param safetyAlertId not used, but this function needs to handle it
  // because it's used in userSearchSelect.component.
  public searchMyGroup(
    limit: number,
    offset: number,
    safetyAlertId?: number,
    search?: string,
    locations?: string[],
    costCenters?: string[],
    otherGroups?: Group[],
    ignoreExclusions: boolean = false
  ): Promise<{ data: UserObj[], totalCount: number}> {
    const sqlId = this.userService.currentUser.sqlId;

    const url = `${environment.apiV2}/users/my-group`;
    let queryParams = new HttpParams({
      fromObject: {
        userId: sqlId.toString(),
        limit: limit.toString(),
        page: offset.toString(),
        sortBy: 'lastName',
        sortOrder: SortOrder.ascending,
      },
    });
    if (search) {
      let searchBy = ["firstName", "lastName", "employeeId"];
      queryParams = queryParams.append('search', search);
      searchBy.forEach((sb) => {
        queryParams = queryParams.append('searchBy[]', sb);
      })
    }
    locations?.forEach((location) => {
      queryParams = queryParams.append('locations[]', location)
    })
    costCenters?.forEach((costCenter) => {
      queryParams = queryParams.append('costCenters[]', costCenter)
    })
    otherGroups?.forEach((group) => {
      queryParams = queryParams.append('otherGroups[]', group.id);
    })
    if (ignoreExclusions) {
      queryParams = queryParams.append('ignoreExclusions', true)
    }

    return this.http
      .get<{ data: UserRow[]; totalCount: number }>(url, { params: queryParams })
      .pipe(
        map(({data, totalCount}) => {
          return {data: data.map((user) => createUserObjFromUserRowRecord(user)), totalCount};
        }),
      )
      .toPromise();
  }

  private fetchMyTeam(): Observable<UserObj[]> {
    const url = `${environment.apiV2}/users/my-team`;
    return this.http.get<UserRow[]>(url).pipe(
      map((userRows: UserRow[]) => {
        return userRows.map((teamMember) => {
          return createUserObjFromUserRowRecord(teamMember);
        });
      }),
    );
  }
}

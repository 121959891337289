import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import {
  createUserObjFromUserRowRecord,
  UserRow,
} from '../../../../../core/dto/userRowRecord';

// services
import { UserDocument } from '../../../../../core/dto/userDoc';
import { UserObj } from '../../../../../core/models/user';
import { TenantDocument } from '../../../../../core/dto';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private usersCollectionObservable: Observable<
    AngularFirestoreCollection<UserDocument>
  >;

  private _users = new BehaviorSubject<UserObj[]>(null);
  public readonly users = this._users.asObservable();

  private _user = new BehaviorSubject<UserObj>(null);
  public readonly user: Observable<UserObj> = this._user.asObservable();

  constructor(private afs: AngularFirestore, private http: HttpClient) {
    this.usersCollectionObservable = this.user.pipe(
      map((user) => {
        if (user) {
          console.time("users");
          let u = this.getUsersCollectionForTenant(user.tenantId);
          console.timeEnd("users")
          return u;
        }
        return null;
      }),
    );

    this.users = this.usersCollectionObservable.pipe(
      switchMap((usersCollection) =>
        usersCollection ? usersCollection.snapshotChanges() : of([]),
      ),
      map((actions) => {
        return actions.map((action) => {
          const data = action.payload.doc.data();
          const id = action.payload.doc.id;
          return { ...data, id };
        });
      }),
    );
  }

  public appendEmailToUsername(username: string) {
    return `psuedoemail+${username}@railtasker.com`;
  }

  private getUsersCollectionForTenant(
    tenantId: string,
  ): AngularFirestoreCollection<UserObj> {
    return this.afs.collection('users', (ref) =>
      ref.where('tenantId', '==', tenantId),
    );
  }

  get currentUser() {
    return this._user.getValue();
  }

  /**
   * Fetches a single user from sql, returning an observable that emits a UserObj
   * @param firestoreId firestoreId of the user to fetch
   */
  public getUserById(firestoreId: string): Observable<UserObj> {
    const url = `${environment.apiV2}/users/by-firestore-id/${firestoreId}`;
    return this.http.get<UserRow>(url).pipe(
      map((userRow) => {
        return createUserObjFromUserRowRecord(userRow);
      }),
    );
  }

  public setUserAndTenantId(user: UserObj): void {
    this._user.next(user);
  }

  /**
   * Fetches a single user from firestore copying properties that match to a UserObj.
   * @param tenantId firebase tenantId of the user to fetch
   * @param username username of the user to fetch
   */
  public async fetchUserByUsername(
    tenantId: string,
    username: string,
  ): Promise<UserObj> {
    const usersCollection = this.afs.collection<UserObj>('users', (ref) =>
      ref
        .where('username', '==', username)
        .where('tenantId', '==', tenantId)
        .where('active', '==', true),
    );
    const userSnapShot = await usersCollection.get().toPromise();
    if (userSnapShot.docs.length < 1) {
      return null;
    }
    return this.fetchUserById(userSnapShot.docs[0].id);
  }
  /**
   * Fetches a batch of users from firestore copying properties that match to a UserObj.
   * @param tenantId firebase tenantId of the user to fetch
   * @param employeeIDs employee ID of the users to fetch
   */
  public  fetchUsersByEmployeeID(
    tenantId: string,
    employeeIDs: string[],
  ) {
    return this.afs.collection<UserObj>('users', (ref) =>
      ref
        .where('employeeId', 'in', employeeIDs)
        .where('tenantId', '==', tenantId)
        .where('active', '==', true),
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => a.payload.doc.data() as UserObj))
    );
  }
  /**
   * Fetches a single user from sql, returning
   * a promise that resolves to a UserObj
   * @param firestoreId firestoreId of the user to fetch
   */
  public async fetchUserById(firestoreId: string): Promise<UserObj> {
    const url = `${environment.apiV2}/users/by-firestore-id/${firestoreId}`;
    return this.http
      .get<UserRow>(url)
      .pipe(map((user) => createUserObjFromUserRowRecord(user)))
      .toPromise();
  }

  public async fetchTenant(tenantId: string): Promise<any> {
    if (tenantId === '') {
      return null;
    }
    const tenant = (
      await this.afs.collection('tenants').doc(tenantId).ref.get()
    ).data() as TenantDocument;
    return { ...tenant };
  }

  public clearUser() {
    this._user = null;
  }
}

import 'isomorphic-fetch';  // Needed for IE11 as polyfill for fetch
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG } from '@angular/fire/compat/analytics';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { environment } from '../environments/environment';
import { LogoutComponent } from './components/logout/logout.component';
import { SsoCallbackComponent } from './components/sso-callback/sso-callback.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { OperationsModuleGuard } from './core/guards/operations-module.guard';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SETTINGS as SETTINGS_FIRESTORE } from '@angular/fire/compat/firestore';


@NgModule({
    declarations: [
        AppComponent,
        LogoutComponent,
        SsoCallbackComponent,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFireFunctionsModule,
        AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
        AngularFireStorageModule,
        AngularFireAuthModule,
        AppRoutingModule,
        ComponentsModule,
        HttpClientModule,
        RouterModule,
        ChartsModule,
    ],
    providers: [
        ScreenTrackingService,
        UserTrackingService,
        AngularFireAuthGuard,
        OperationsModuleGuard,
        { provide: CONFIG, useValue: {
                allow_ad_personalization_signals: false,
                anonymize_ip: true
            } },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        HttpClient,
        InAppBrowser,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
          provide: SETTINGS_FIRESTORE,
          useValue: { experimentalForceLongPolling: true },
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

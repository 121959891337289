import firebase from 'firebase/compat/app';

import { createDate } from '../utils/dates/create-date';
import { ChecklistDocument } from '../../core/dto/checklistDoc';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';

export class Checklist implements ChecklistDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date | firebase.firestore.Timestamp;
  id: string;
  lastUpdatedOn: Date | firebase.firestore.Timestamp;
  updatedBy: string; // AccountHolder Id

  activeTrainees: number;
  documentUrl: string;
  documentName: string;
  name: string;
  groupId: string;
  groupName: string;
  uniqueId: string;
  sequencedSections: boolean;
  designatedInstructorOnly: boolean;
  limitedTrainees: boolean;
  limitedSignOff: boolean;
  enableSelfReporting: boolean;
  enableMinimumHours: boolean;
  minimumHours: number | null;
  expireQualifiedPerson: number | null; // in days
  isTestingSupervisorEnabler: boolean;

  assignQPasDI?: boolean;
  deleted?: boolean;

  public constructor(properties?: any) {
    this.archived = properties.archived || false;
    this.createdBy = properties.createdBy || '';
    this.createdOn = properties.createdOn ? createDate(properties.createdOn) : new Date();
    this.id = properties.id || '';
    this.lastUpdatedOn = properties.lastUpdatedOn ? createDate(properties.lastUpdatedOn) : new Date();
    this.updatedBy = properties.updatedBy || '';

    this.activeTrainees = properties.activeTrainees || 0;
    this.documentUrl = properties.documentUrl || '';
    this.documentName = properties.documentName || '';
    this.name = properties.name || '';
    this.groupId = properties.groupId || '';
    this.groupName = properties.groupName || '';
    this.uniqueId = properties.uniqueId || '';
    this.sequencedSections = properties.sequencedSections || false;
    this.designatedInstructorOnly = properties.designatedInstructorOnly || false;
    this.limitedTrainees = properties.limitedTrainees || false;
    this.limitedSignOff = properties.limitedSignOff || false;
    this.enableSelfReporting = properties.enableSelfReporting || false;
    this.enableMinimumHours = properties.enableMinimumHours || false;
    this.minimumHours = properties.minimumHours || null;
    this.assignQPasDI = properties.assignQPasDI || false;
    this.deleted = properties.deleted || false;
    this.expireQualifiedPerson = properties.expireQualifiedPerson || null;
    this.isTestingSupervisorEnabler = properties.isTestingSupervisorEnabler || false;

  }

  public firestoreObject() {
    return { ...this };
  }
}

export class ChecklistSearchModel {
  doc: ChecklistDocument;
  id: string;
  tenantId: string;
  constructor(doc: QueryDocumentSnapshot<ChecklistDocument>, tenantId: string) {
    this.doc = doc.data();
    this.id = doc.id;
    this.tenantId = tenantId;
  }

  public toAlgolia() {
    const { archived, uniqueId, name, createdOn, lastUpdatedOn } = this.doc;
    const searchObj = {
      objectID: this.id,
      tenantId: this.tenantId,
      name,
      archived,
      uniqueId,
      //https://www.algolia.com/doc/guides/sending-and-managing-data/prepare-your-data/in-depth/what-is-in-a-record/#dates
      createdOn: new Date(createdOn?.valueOf()).getTime(),
      lastUpdatedOn: new Date(lastUpdatedOn?.valueOf()).getTime(),
    };
    return searchObj;
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Tenant } from '../../../../../core/models/tenant';
import { TenantService } from '../services/tenant.service';

@Injectable({ providedIn: 'root' })
export class OperationsModuleGuard implements CanActivate {
  constructor(
    private router: Router,
    private tenantService: TenantService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.tenantService.tenant.pipe(
      filter(tenant => !!tenant),
      map((tenant: Tenant) => {
        if (tenant.operationsModule) {
          return true;
        }
        return false;
      }),
      tap(canView => {
        if (!canView) {
          this.router.navigate(['/home']);
        }
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Tenant } from '../../../../../core/models/tenant';
import { BehaviorSubject, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { UserObj } from '../../../../../core/models/user';

@Injectable({ providedIn: 'root' })
export class TenantService {
  private _tenant = new BehaviorSubject<Tenant>(null);
  public tenant = this._tenant.asObservable();
  public currentTenant = this._tenant.pipe(take(1)).toPromise();

  constructor(private afs: AngularFirestore, private userService: UserService) {
    this.userService.user
      .pipe(
        switchMap((user) => {
          if (user) {
            return this.getUserTenant(user);
          }
          return of(null);
        }),
      )
      .subscribe((tenant) => {
        if (tenant) {
          return this._tenant.next(tenant);
        }
        this._tenant.next(null);
      });
  }

  getUserTenant(user: UserObj) {
    return this.afs
      .doc(`/tenants/${user.tenantId}`)
      .get()
      .pipe(
        map((snap) =>
          snap.exists
            ? new Tenant({
                ...(snap.data() as Tenant),
                id: snap.id,
              })
            : null,
        ),
      );
  }
}

import { DateTime } from 'luxon';

import { ChecklistHourLog } from '../dto/checklstHourLog';
import { ChecklistAssignedDocument } from '../dto/checklistAssignedDoc';
import { ChecklistStatus } from '../enums/checklistStatus';
import { createDate } from '../utils/dates/create-date'

export class ChecklistAssigned implements ChecklistAssignedDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date;
  id: string;
  lastUpdatedOn: Date;
  updatedBy: string;  // AccountHolder Id

  // Checklist properties
  checklistId: string;
  checklistName: string;
  checklistUniqueId: string;
  expireQualifiedPerson?: number; // in days
  expireQualifiedPersonDate?: Date;
  minimumHours?: number;
  enableSelfReporting: boolean;

  // User properties
  userId: string;
  userUsername: string;
  userEmployeeId: string;
  userName: string;
  userFirstName: string;
  userLastName: string;
  userGroupName: string;
  userGroupId: string;
  userLocation?: string;
  userTitle?: string;

  status: ChecklistStatus;
  statusIsActive: boolean;
  startDate: Date;
  dateCompleted: Date;
  dateCompletedTimeZone: string;
  designatedInstructor: string;
  designatedInstructorEmployeeId: string;
  lastAttempt: Date;
  progress: number;
  comment: string;

  keywords?: string[];
  hourLogs?: ChecklistHourLog[];
  lastSqlSyncRequest?: Date;

  public constructor(assignment?: ChecklistAssignedDocument | ChecklistAssignedDocument | Partial<ChecklistAssigned> ) {
    this.archived = assignment.archived || false;
    this.createdBy = assignment.createdBy || '';
    this.createdOn = assignment.createdOn ? createDate(assignment.createdOn) : new Date();
    this.id = assignment.id || '';
    this.lastUpdatedOn = assignment.lastUpdatedOn ? createDate(assignment.lastUpdatedOn) : null;
    this.updatedBy = assignment.updatedBy || '';

    this.checklistId = assignment.checklistId || '';
    this.checklistName = assignment.checklistName || '';
    this.checklistUniqueId = assignment.checklistUniqueId || '';

    this.userId = assignment.userId || '';
    this.userUsername = assignment.userUsername || '';
    this.userEmployeeId = assignment.userEmployeeId || '';
    this.userName = assignment.userName || '';
    this.userFirstName = assignment.userFirstName || '';
    this.userLastName = assignment.userLastName || '';
    this.userGroupName = assignment.userGroupName || '';
    this.userGroupId = assignment.userGroupId || '';
    this.userLocation = assignment.userLocation || '';
    this.userTitle = assignment.userTitle || '';

    this.status = assignment.status || ChecklistStatus.assigned;
    this.statusIsActive = assignment.statusIsActive || false;
    this.startDate = assignment.startDate ? createDate(assignment.startDate) : null;
    this.dateCompleted = assignment.dateCompleted ? createDate(assignment.dateCompleted) : null;
    this.dateCompletedTimeZone = assignment.dateCompletedTimeZone || '';
    this.designatedInstructor = assignment.designatedInstructor || '';
    this.designatedInstructorEmployeeId = assignment.designatedInstructorEmployeeId || '';
    this.lastAttempt = assignment.lastAttempt ? createDate(assignment.lastAttempt) : null;
    this.progress = assignment.progress || 0;
    this.comment = assignment.comment || '';

    this.keywords = assignment.keywords || [];
    this.hourLogs = assignment.hourLogs || [];

    if (assignment.hourLogs && assignment.hourLogs.length) {
      this.hourLogs = assignment.hourLogs.map(log => {
        return {
          createdOn: createDate(log.createdOn),
          datePerformed: createDate(log.datePerformed),
          hoursCompleted: log.hoursCompleted,
          recordedById: log.recordedById,
          recordedByName: log.recordedByName,
          comment: log.comment || '',
        };
       })
    }

    if (assignment.expireQualifiedPerson) {
      this.expireQualifiedPerson = assignment.expireQualifiedPerson;
    }
    if (assignment.expireQualifiedPersonDate) {
      this.expireQualifiedPersonDate = createDate(assignment.expireQualifiedPersonDate);
    }
    if (assignment.minimumHours) {
      this.minimumHours = assignment.minimumHours;
	  }
    if (assignment.startDate) {
      this.startDate = createDate(assignment.startDate);
    }
    if (assignment.lastSqlSyncRequest) {
      this.lastSqlSyncRequest = createDate(assignment.lastSqlSyncRequest);
    }

  }

  // Sum of all hours logged on this checklist assignment to date
  public get totalHoursLogged(): string {
    let totalHours = 0;
    if (!this.minimumHours) {
      return null;

    } else if (this.hourLogs && this.hourLogs.length) {
      for (const log of this.hourLogs) {
        totalHours += log.hoursCompleted;
      }
    }
    return totalHours.toFixed(2);
  }

  // Number of hours needed to meet minimum hour requirement
  // Returns null of no minimum hour requirement is set
  // Returns 0 if minimum hour requirement has been exceeded
  public get hoursToComplete(): string {
    if (!this.minimumHours) {
      return null;
    }
    const hoursRemaining = this.minimumHours - Number(this.totalHoursLogged);
    return hoursRemaining > 0 ? hoursRemaining.toFixed(2) : '0';
  }

  /**
   * Recalculates and sets and returns expireQualifiedPersonDate
   * Calculates Expiration date based on dateCompleted and expireQualifiedPerson days
   *
   * @returns {Date}
   * @memberof ChecklistAssigned
   */
  public recalcExpirationDate(): Date {
    if (!this.dateCompleted) {
      return null;
    }
    // const startDate = this.dateCompleted instanceof(Date) ? this.dateCompleted
    this.expireQualifiedPersonDate = this.addDaysWithTZ(this.dateCompleted, this.expireQualifiedPerson, this.dateCompletedTimeZone);
    // DateTime.fromJSDate(this.dateCompleted, {zone: this.dateCompletedTimeZone ? this.dateCompletedTimeZone : 'America/Chicago'})
    //   .plus({ days: this.expireQualifiedPerson }).startOf('day').toJSDate();
    return this.expireQualifiedPersonDate;
  }

  /**
   * Retuns a future date incremented a number of days from the startDate
   * with a time set to start of day for the provided timezone.
   *
   * @param {Date} startDate
   * @param {number} daysToAdd
   * @param {string} timeZone
   * @returns
   * @memberof ChecklistAssigned
   */
  public addDaysWithTZ(startDate: Date, daysToAdd: number, timeZone: string = '') {
    const options = timeZone ? { zone: timeZone } : {};
    return DateTime.fromJSDate(startDate, options)
    .plus({ days: daysToAdd }).startOf('day').toJSDate();
  }

  public firestoreObject() {
    return { ...this };
  }
}

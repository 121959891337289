import { TestCategoryDocument, TestNameDocument } from "../dto";
import { convertTimestamp } from "../utils/dates/convertTimestamp";

export class TestCategory implements TestCategoryDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date;
  id: string;
  lastUpdatedOn: Date;
  updatedBy: string;  // AccountHolder Id

  label: string;
  testNames: TestNameDocument[];

  constructor(category: TestCategory | TestCategoryDocument) {
    this.assignValues(category);
  }
  
  assignValues(category: Partial<TestCategory | TestCategoryDocument>) {
    this.archived = category.archived === false ? category.archived : category.archived || undefined;
    this.createdBy = category.createdBy || undefined;
    this.id = category.id || undefined;
    this.updatedBy = category.updatedBy || undefined;
    this.label = category.label || undefined;
    this.testNames = category.testNames || undefined;
    this.createdOn = category.createdOn ? convertTimestamp(category.createdOn) : undefined;
    this.lastUpdatedOn = category.lastUpdatedOn ? convertTimestamp(category.lastUpdatedOn) : undefined;
  }

  firestoreObject() {
    return { ...this }
  }
}

/**
 * Makes an attempt to create a date from various input types.
 * Valid input types are: Date, ISO-String, Milliseconds, firestore.Timestamp, Obj.seconds
 *
 * @export
 * @template T
 * @param {T} arg
 * @returns {Date}
 */
export function createDate<T>(arg: T): Date {
  switch (typeof arg) {
    case 'object': {
      if (arg instanceof (Date)) {
        return arg;
      }
      if (Object(arg)._seconds) {
        return new Date((Object(arg)._seconds * 1000));
      }
      if (Object(arg).seconds) {
        if (Object(arg).nanoseconds) {
          const nDate = new Date((Object(arg).seconds * 1000));
          nDate.setMilliseconds(Object(arg).nanoseconds / 1000000);
          return nDate;
        }
        return new Date((Object(arg).seconds * 1000));
      }
      return null;
    }
    case 'string': {
      return new Date(arg);
    }
    case 'number': {
      return new Date(arg)
    }
    default: {
      return null;
    }
  }
}

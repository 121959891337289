<!-- Side menu -->
<ion-app>
  <ion-menu side="end" menuId="first" contentId="content">
    <ion-header no-border class="ion-no-border">
      <ion-toolbar color="secondary">
        <ion-buttons slot="end">
          <ion-button fill="clear" (click)="close()">
            <ion-icon slot="icon-only" size="large" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="start">
          <ion-button fill="clear" routerLinkActive="active" routerLink="/home" (click)="close()">
            <ion-icon slot="icon-only" size="large" name="home"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="side-menu-background" color="secondary">
      <ion-list class="ion-padding-horizontal side-menu-background" lines="none" color="secondary">
        <h3 class="ion-text-start" style="color: white;">
          Checklists
        </h3>
        <ion-item color="secondary" routerLinkActive="active" routerLink="/all-checklists" (click)="close()" mode="md" detail="false">
          <ion-label>All Checklists</ion-label>
        </ion-item>
        <ion-item color="secondary" routerLinkActive="active" [routerLink]="['/training', user && user.id]" (click)="close()" mode="md" detail="false">
          <ion-label>My Training</ion-label>
        </ion-item>
        <ion-item color="secondary" routerLinkActive="active" routerLink="/trainee-search" (click)="close()" mode="md" detail="false">
          <ion-label>Search Trainee Profiles</ion-label>
        </ion-item>
      </ion-list>
      <ion-list *ngIf="tenant && tenant.operationsModule" class="ion-padding-horizontal side-menu-background" lines="none">
        <h3 class="ion-text-start" style="color: white;">
          {{ this.tenant?.id === 'ns' ? 'PSEs' : 'Tests' }}
        </h3>
        <ion-item *ngIf="user && user.permissions && user.permissions.testSupervisor" color="secondary" routerLinkActive="active" routerLink="/operations/tabs/employee-tests" (click)="close()" mode="md" detail="false">
          <ion-label routerLinkActive="active" > {{ this.tenant?.id === 'ns' ? 'All PSEs' : 'All Tests' }}</ion-label>
        </ion-item>
        <ion-item *ngIf="user && user.permissions && user.permissions.testSupervisor" color="secondary" routerLinkActive="active" routerLink="/operations/tabs/dashboard" (click)="close()" mode="md" detail="false">
          <ion-label>Dashboard</ion-label>
        </ion-item>
        <ion-item *ngIf="tenant?.id !== 'ns'" color="secondary" routerLinkActive="active" routerLink="/operations/tabs/my-tests" (click)="close()" mode="md" detail="false">
          <ion-label>My Tests</ion-label>
        </ion-item>
        <ion-item *ngIf="tenant?.id === 'ns'" color="secondary" (click)="close()" mode="md" detail="false" href="https://achieve.nscorp.com/AchieveWebApp/index.html#dashboard" target="_blank">
          <ion-label>Achieve</ion-label>
        </ion-item>
      </ion-list>
      <ion-item *ngIf="tenant?.safetyAlertsModule" color="secondary" lines="none" routerLinkActive="active" routerLink="/safety-alerts" (click)="close()" mode="md" detail="false">
        Safety Engagements
      </ion-item>
      <ion-item *ngIf="userIsSaManager" color="secondary" lines="none" routerLinkActive="active" routerLink="/my-team" (click)="close()" mode="md" detail="false">
        My Team
      </ion-item>
      <ion-item color="secondary" lines="none" routerLinkActive="active" routerLink="/profile" (click)="close()" mode="md" detail="false">
        My Profile
      </ion-item>
      <ion-item color="secondary" lines="none" routerLinkActive="active" routerLink="/admin-app" (click)="close()" mode="md" detail="false">
        Admin Site
      </ion-item>
    </ion-content>
    <ion-footer no-border color="secondary" class="side-menu-background ion-no-border">
      <ion-toolbar color="secondary">
        <ion-row class="ion-justify-content-center">
          <p class="ion-text-center" style="color: white;">
            <b>{{ this.tenant?.id === 'ns' ? 'Contact Support' : 'Contact Us' }}</b>
            <br>
            {{ this.tenant?.id === 'ns' ? 'railtaskeradmins@nscorp.com' : 'support@sparkts.net' }}
          </p>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-button (click)="logout()" shape="round"><b>LOG OUT</b></ion-button>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <small style="color: white;">v{{versionString}}</small>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="content"></ion-router-outlet>
</ion-app>

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: 'sandbox',
  production: false,
  firebase: {
    apiKey: 'AIzaSyD13Rfv4BnspepASiJBjggB2feISoiUSoE',
    authDomain: 'rail-tasker-sandbox.firebaseapp.com',
    databaseURL: 'https://rail-tasker-sandbox.firebaseio.com',
    projectId: 'rail-tasker-sandbox',
    storageBucket: 'rail-tasker-sandbox.appspot.com',
    messagingSenderId: '446475276228',
    appId: "1:446475276228:web:8b68e07f4200743f5dde91",
    measurementId: 'G-CKQ41YECH1'
  },
  adminAppUrl: 'https://rail-tasker-sandbox.web.app/',
  samlServiceUrl: 'https://rail-tasker-sandbox.appspot.com',
  apiV1: 'https://api-service-dot-rail-tasker-sandbox.appspot.com/api/v1', // url for API v1
  apiV2: 'https://api-v2-dot-rail-tasker-sandbox.uc.r.appspot.com',
  // apiV2: 'http://localhost:8081',
  bnsfUnauthorizedMessage1:
    'This application is intended for use by Operations employees only.',
  bnsfUnauthorizedMessage2:
    'For access, please call BNSF’s Help Desk at (817) 593-4357',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { MenuController } from '@ionic/angular';
import { filter, takeUntil } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';

import packageInfo from '../../package.json';
import { AuthService } from './core/auth/auth.service';
import { UserService } from './core/services/user.service';
import { UserObj } from '../../../core/models/user';
import { environment } from '../environments/environment';
import { TenantService } from './core/services/tenant.service';
import { Tenant } from '../../../core/models/tenant';
import { SafetyAlertService } from './core/services/safety-alert.service';
const { SplashScreen } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public user: UserObj;
  public tenant: Tenant;
  public versionString = packageInfo.version;
  public envName = environment.envName === 'production' ? '' : environment.envName.toUpperCase();
  public userIsSaManager = false;

  constructor(
    private authService: AuthService,
    public menuCtrl: MenuController,
    private platform: Platform,
    private router: Router,
    private userService: UserService,
    private tenantService: TenantService,
    private safetyAlertService: SafetyAlertService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      combineLatest([
        this.tenantService.tenant,
        this.userService.user,
        this.safetyAlertService.userIsSaManager,
      ]).pipe(
        filter(([tenant, user, userIsManager]) => !!(user && tenant)),
        takeUntil(this.destroy$),
      ).subscribe(([tenant, user, userIsManager]) => {
        this.user = user;
        this.tenant = tenant;
        this.userIsSaManager = userIsManager;
      });
      SplashScreen.hide();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    if (this.destroy$) { this.destroy$.unsubscribe(); }
  }

  logout() {
    if (!this.userService.currentUser) {
      this.router.navigate(['/login']);
      this.menuCtrl.close();
    }
    const tenantId = this.userService.currentUser.tenantId;
    this.authService.signOut().then(() => {
      // Hit SSO Logout workflow for auth and bnsf.
      // TODO use tenant.ssoLogout setting from firestore instead of hardcoding.
      //  only hardcoded for quick bnsf go live 9/14/2020
      if (tenantId === 'auth0' || tenantId === 'bnsf') {
        const logoutUrl =
        `${environment.samlServiceUrl}/app/logout?tenant=${tenantId}&name_id=${this.userService.currentUser.employeeId}`;
        window.open(logoutUrl, '_self');
        return;
      }
      this.router.navigate(['/login']);
      this.menuCtrl.close();
    });
  }

  close() {
    this.menuCtrl.close();
  }

}

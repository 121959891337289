
import { DateTime } from 'luxon';
import { UserObj } from "./user";
import {
  TestSubdivisionDocument,
  TestTerminalDocument,
  EventDocument,
  TestTypeDocument,
  EventObject
} from "../dto";
import { convertTimestamp } from "../utils/dates/convertTimestamp";
import { TestSubtype } from './test-subtype';
import { TestEvent } from './test-event';
import { TestDistrict } from './test-district';
export class Event implements EventDocument {
    tests: TestEvent[];
    eventName: string;
    archived: boolean;
    createdBy: string; // AccountHolder Id
    createdOn: Date; // Date the test was entered
    id: string;
    lastUpdatedOn: Date;
    updatedBy: string;  // AccountHolder Id

    type: TestTypeDocument;
    subtypes: TestSubtype[];
    supervisors: UserObj[];
    supervisorIds: string[]; // need for array contains query
    employee: UserObj;
    locationDetails: {
      subdivision: TestSubdivisionDocument,
      district?: TestDistrict,
      terminal?: TestTerminalDocument,
      startMilepost?: string,
      endMilepost?: string,
    }
    jobDuty?: string = null;
    trainOrJob: string;
    timeOfDay: 'Daylight' | 'Dark';
    comments?: string;
    date: Date; // Date the event was performed
    startTime?: Date = null;
    endTime?: Date = null;
    testGroup?: string = null;
    trainType?: string = null;
    followUpDetails?: string;
  event: any;
    constructor(event?: Event | EventDocument | EventObject) {
      if (!event) {
        this.assignBlankValues();
      } else {
        this.assignValues(event);
      }
    }


  get valid() {
    const values = Object.values(this).filter(value => value === undefined);
    return values.length <= 0;

  }

  /**
   * Returns the time the test occurred
   */
  get time() {
    const d = DateTime.fromJSDate(this.date);
    return d.toFormat('h:mm a');
  }

  /**
   * Returns true if test occurred on a weekday
   */
  get weekday() {
    const dayOfWeek = DateTime.fromJSDate(this.date).weekday;
    return dayOfWeek > 0 && dayOfWeek < 6;
  }

  /**
   * Returns true if more than one supervisor
   * conducted the test
   */
  get teamTest(): boolean {
    return this.supervisors.length > 1;
  }

  // Represents whether the test event is editable in the mobile app
  get canEdit(): boolean {
    const daysApart = DateTime.fromJSDate(this.createdOn).diffNow('days').days;
    return daysApart > -10;
  }

  // Represents whether the test event is editable in the admin app
  get adminAppEditable() {
    const daysApart = DateTime.fromJSDate(this.createdOn).diffNow('days').days;
    return daysApart > -90;
  }



  // Used to create a blank event object
  private assignBlankValues() {
    this.tests = [];
    this.eventName = undefined;
    this.archived = undefined;
    this.createdBy = undefined;
    this.createdOn = undefined;
    this.id = undefined;
    this.lastUpdatedOn = undefined;
    this.updatedBy = undefined;
    this.type = undefined;
    this.supervisors = undefined;
    this.supervisorIds = undefined;
    this.employee = undefined;
    this.locationDetails = {
      subdivision: undefined,
      district: undefined,
      terminal: undefined,
      startMilepost: undefined,
      endMilepost: undefined,
    };
    this.trainOrJob = undefined;
    this.timeOfDay = undefined;
    this.startTime = undefined;
    this.endTime = undefined;
    this.date = new Date();
    this.jobDuty = null;
    this.testGroup = null;
    this.trainType = null;
  }


  private assignValues(event: Partial<Event | EventDocument | EventObject | any>) {
    this.tests = event.tests;
    this.eventName = event.eventName;
    this.archived = event.archived;
    this.createdBy = event.createdBy;
    this.createdOn = event.createdOn ? convertTimestamp(event.createdOn) : undefined;
    this.id = event.id;
    this.lastUpdatedOn = event.lastUpdatedOn ? convertTimestamp(event.lastUpdatedOn) : undefined;
    this.updatedBy = event.updatedBy;
    this.type = event.type;
    this.supervisors = event.supervisors.map((s: any) => new UserObj(s));
    this.supervisorIds = event.supervisorIds;
    this.employee = new UserObj(event.employee);
    this.locationDetails = {
      subdivision: event.locationDetails && event.locationDetails.subdivision || event.subdivision,
      district: event.locationDetails && event.locationDetails.district || event.district || null,
      terminal: event.locationDetails && event.locationDetails.terminal || event.terminal || null,
      startMilepost: event.locationDetails && event.locationDetails.startMilepost ? event.locationDetails.startMilepost : event.startMilepost || null,
      endMilepost: event.locationDetails && event.locationDetails.endMilepost ? event.locationDetails.endMilepost : event.endMilepost || null,
    };
    this.trainOrJob = event.trainOrJob;
    this.timeOfDay = event.timeOfDay;
    this.jobDuty = event.jobDuty ? event.jobDuty : null;
    this.testGroup = event.testGroup ? event.testGroup : null;
    this.subtypes = event.subtypes ? event.subtypes : null;
    this.date = event.date ? convertTimestamp(event.date) : undefined;
    if (event.comments) { this.comments = event.comments; }
    if (event.startTime) { this.startTime = event.startTime ? convertTimestamp(event.startTime) : undefined; }
    if (event.trainType) {this.trainType = event.trainType;}
    if (event.followUpDetails) { this.followUpDetails = event.followUpDetails; }

  }

  firestoreObject() {
    const employee = { ...this.employee }
    const supervisors = this.supervisors.map(s => ({ ...s}));
    const subdivision = { ...this.locationDetails.subdivision };
    const terminal = this.locationDetails.terminal ? { ...this.locationDetails.terminal } : null;
    return {
      ...this,
      employee,
      supervisors,
      locationDetails: {
        ...this.locationDetails,
        subdivision,
        terminal,
      }
    };
  }

}

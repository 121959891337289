export class PaginatedSearchQuery {
  public page: number;
  public limit: number;
  public orderBy: string;
  public sortOrder: 'ASC' | 'DESC';
  public search: string;
  public idToQuery: string;
  public filter?: string;
  public filterArray?: { key: string, value: string }[];
  // used to filter checklist-assigned searches
  // string represents a pipe-delimited string array
  public statuses?: string;
  // Postgres sorts nulls first when sorting dates
  // Use this to override default behavior
  public sortOrderNulls?: 'FIRST' | 'LAST';
  public includeInactive?: boolean;
  public active?: boolean;

  constructor(val: Partial<PaginatedSearchQuery>) {
    if (val) {
      this.assignValues(val);
    }
  }

  private assignValues(val: Partial<PaginatedSearchQuery>) {
    this.page = val.page || 0;
    this.limit = val.limit || null;
    this.orderBy = val.orderBy || null;
    this.sortOrder = val.sortOrder || 'ASC';
    this.search = val.search || null;
    this.idToQuery = val.idToQuery || null;
    if (val.filter) { this.filter = val.filter; }
    if (val.filterArray) { this.filterArray = val.filterArray; }
    if (val.statuses) { this.statuses = val.statuses }
    if (val.sortOrderNulls) { this.sortOrderNulls = val.sortOrderNulls }
    if (val.includeInactive) { this.includeInactive = val.includeInactive }
    if (val.active != null || val.active != undefined) { this.active = val.active }
  }

  setLimit(val: number) {
    this.limit = val;
  }

  setOrderBy(val: string) {
    this.orderBy = val;
  }

  setSortOrder(val: 'ASC' | 'DESC') {
    this.sortOrder = val;
  }

  setPage(val: number) {
    this.page = val;
  }

  setSearch(val: string) {
    this.search = val;
  }

  setIdToQuery(val: string) {
    this.idToQuery = val;
  }

  build() {
    return { ...this };
  }

  private throwError(param: string) {
    throw new Error(`${param} is required for query`);
  }
}

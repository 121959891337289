import { createDate } from '../utils/dates/create-date';
import { TaskCategoryDoc } from '../dto';

export class TaskCategory implements TaskCategoryDoc {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date;
  id: string;
  lastUpdatedOn: Date;
  updatedBy: string;  // AccountHolder Id
  checklistId: string;
  name: string;

  constructor(category: TaskCategory | TaskCategoryDoc) {
    this.archived = category.archived || false;
    this.createdBy = category.createdBy || '';
    this.createdOn = createDate(category.createdOn) || new Date();
    this.id = category.id || '';
    this.lastUpdatedOn = createDate(category.lastUpdatedOn) || new Date();
    this.updatedBy = category.updatedBy || '';
    this.checklistId = category.checklistId || '';
    this.name = category.name || '';
  }

  public firestoreObject(): Object {
    return { ...this };
  }
}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { UserObj } from '../../../../../core/models/user';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authState = new BehaviorSubject<firebase.User>(null);
  public readonly authState = this._authState.asObservable();
  private _user = new BehaviorSubject<UserObj>(null);
  public readonly user = this._user.asObservable();
  public tenantId: string;
  public userId: string;
  public token = null; // needed for http requests
  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
  ) {
    this.afAuth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        this.afAuth.authState
          .pipe(
            map((user: firebase.User) => {
              this._authState.next(user);
              return user;
            }),
            switchMap(async (user: firebase.User) => {
              // get firebase token
              if (user) {
                this.token = await user.getIdToken(true);
              }

              return user;
            }),
            switchMap((user: firebase.User) => {
              // checks firebase user for provider data.
              // will probably need to double check this does what we want it to once SSO has been hashed out.
              if (
                user &&
                user.providerData.length > 0 &&
                user.providerData[0]?.uid != null &&
                user.providerData[0]?.providerId !== 'password'
              ) {
                return this.userService.fetchUserByUsername(
                  user.providerData[0].providerId.substring(5),
                  user.providerData[0].uid,
                );
              }
              return user
                ? this.userService.getUserById(user.uid)
                : Promise.resolve(null);
            }),
          )
          .subscribe((user) => {
            if (user && user.active) {
              if (user.tenantId === 'kcs') {
                this.signOut().then(() => {
                  this.router.navigate(['/login']);
                });
              } else {
                this.userService.setUserAndTenantId(user);
              }
            } else if (user && !user.active) {
              this.signOut().then(() => {
                this.router.navigate(['/login']);
              });
            }
          });
      });
  }

  // returns true if the user is logged in
  get authenticated(): boolean {
    return this._authState.getValue() !== null;
  }

  // returns current user data
  get currentUserAuthState(): any {
    return this.authenticated ? this._authState.getValue() : null;
  }

  // returns authstate observable
  get currentAuthStateObservable(): any {
    return this.afAuth.authState;
  }

  // returns current user UID
  get uid(): string {
    return this.authenticated ? this._authState.getValue().uid : null;
  }

  // returns current user email
  get email(): string {
    return this.authenticated ? this._authState.getValue().email : null;
  }

  // sign in using email, password
  public async signIn(email: string, password: string): Promise<any> {
    await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  // signs out user
  public async signOut(reload = true) {
    await this.afAuth.signOut();

    if (reload) {
      location.reload();
    }
  }

  // register with email and password
  public register(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  // reset password
  public resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  public async signInSSO(providerId: string) {
    const provider = new firebase.auth.SAMLAuthProvider(providerId);
    await this.afAuth.signInWithRedirect(provider);
  }

  public async getRedirectResult() {
    try {
      return this.afAuth.getRedirectResult();
    } catch (error) {
      console.error('error in redirectRes', error);
      return null;
    }
  }

  public async signInWithFBToken(fbToken: string) {
    const userCred = await this.afAuth.signInWithCustomToken(fbToken);

    if (userCred.user?.uid) {
      const user = await this.userService.fetchUserById(userCred.user.uid);
      if (user && user.active) {
        return userCred;
      }
    }

    await this.signOut(false);
    throw new Error('Not allowed: Inactive user account.');
  }

  public changePassword(password: string): Promise<boolean> {
    return firebase
      .auth()
      .currentUser.updatePassword(password)
      .then(
        (res) => Promise.resolve(true),
        (err) => Promise.resolve(false),
      );
  }

  public reauthenticate(password: string): Promise<boolean> {
    const credentials = firebase.auth.EmailAuthProvider.credential(
      this.email,
      password,
    );
    return firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credentials)
      .then(
        (res) => Promise.resolve(true),
        (err) => Promise.resolve(false),
      );
  }

  async getIdToken() {
    const user = await this.afAuth.currentUser;
    if (user) {
      return user.getIdToken(true);
    } else {
      return null;
    }
  }
}

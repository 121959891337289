import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;
@Component({
  selector: 'app-sso-callback',
  templateUrl: './sso-callback.component.html',
  styleUrls: ['./sso-callback.component.scss'],
})
export class SsoCallbackComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private platform: Platform,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
    // no unsubscribe is needed as Router manages it's observables
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    // const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    // console.log('token from active route', token);

    this.authService.signInWithFBToken(token).then((userCred) => {
      this.userService.fetchUserById(userCred.user.uid).then(userObj => {
        if (!userObj || userObj.id === '') {
          this.router.navigate([`/unauthorized/${userCred.user.tenantId}`]);
        } else {
          if (this.platform.is('capacitor')) {
            Storage.set({
              key: 'appPreferences',
              value: JSON.stringify({
                ssoTenantId: userObj.tenantId
              })
            })
            .then(() => this.router.navigate(['/home']))
            .catch((error) => console.error('Error storing item', error));
          } else {
            this.router.navigate(['/home']);
          }
        }
      });
    }).catch(err => {
      console.error('Bad token');
      this.router.navigate(['/unauthorized']);
    });
  }
}
